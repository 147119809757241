html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  */
.logo {
  float:left;
  margin: 0 0 0 0; 
  height:3em;
} 

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  border-radius: 10px;
}

a {
  color: darkgrey !important;
  overflow: hidden;
  font-size: 90% !important;
}

.map-container {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.legend {
  opacity: 0.9;
  width: 200px;
  height: 200px;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgb(10, 25, 41);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mainnavlink {
  white-space: nowrap;
}
.mainnavlink:hover {
  border-bottom: 2px solid green;
  padding-bottom: 4px;
}


/* change background and tip color to green */
.mypopup .mapboxgl-popup-content {
  opacity:0.9 !important;
  background-color: rgb(10, 25, 41);
  color: lightgrey !important;
}

.mypopup .mapboxgl-popup-tip {
  opacity:0.9 !important;
  border-top-color: rgb(10, 25, 41)
}

.sidenav {
  z-index: 10;
  position: absolute;
  top:50px;
  left:0px;
  width: 450px;
  transition: left 0.3s ease-in-out;
  height: 100vh;
  /* background-color: rgb(10,25,41); */
  background-color: rgb(255,255,255);
  /* opacity:0.9; */
  overflow-y: scroll;
}
.sidenavClosed {
  z-index: 10;
  position: absolute;
  top:10px; 
  transition: left 0.3s ease-in-out;
  left:-430px;
  height: 100vh;
  /* background-color: rgb(10,25,41); */
  background-color: rgb(255,255,255);
  /* overflow: hidden; */
}

.divBtn {
  /* display: flex; justify-content: flex-end */
  position: absolute;
  right:-28px
}

.menuBtn {

  color: #e0dada;
  background-color: rgb(10,25,41);
  border: none;
  cursor: pointer;
  /* padding-right: 20px; */
}

.form-control{
  line-height: 0.5;
  font-size: 0.8rem;
}

.input-group-text{
  line-height: 0.5;
  font-size: 0.8rem;
}

textarea{
  font-size: 0.8rem;
}

.my_spinner{
  right: 50%; 
  top: 50%;
  position:absolute; 
}